
import { Filter } from '@/types/ListTypes';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'SingleValueFilterDisplayValue',
})
export default class SingleValueFilterDisplayValue extends Vue {
  @Prop({ required: true })
  private readonly filter!: Filter;

  @Prop({ default: (data: string|number) => (data).toString() })
  private readonly converter!: (data: string|number) => string;

  protected getDisplayValue(): string {
    const displayValue = this.filter.data;

    if (typeof displayValue === 'string' || typeof displayValue === 'number') {
      return this.converter((displayValue).toString());
    }

    throw Error('No display value found');
  }

  protected removeFilter(): void {
    this.filter.data = null;
    this.filter.applied = false;

    this.$emit('filter-removed');
  }
}
